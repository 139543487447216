import { Divider, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ExternalLayout from "../../../components/Layout/ExternalLayout";
import { useStoreActions, useStoreState } from "../../../hooks";
import { ReactSVG } from "react-svg";
import { useState, useEffect } from "react";
import EditDialog, { editDialogRef } from "./Edit/EditDialog";
import EditIcon from "../Icons/EditIcon.svg";
import { Status } from "../../../models/status";
import { useNavigate } from "react-router-dom";
import "./DesignSummary.css";
import "./DesignSummary-1366.css";
import Spinner from "../../../components/Spinner/Spinner";
import ProjectService from "../../../services/ProjectService";
import DownloadProjectDialog, { downloadProjectDialogRef } from "./Download/DownloadProjectDialog";
import DesignSummaryTable from "./Table/DesignSummaryTable";
import { IProductDesignFilter } from "../../../interfaces/Filter/IProductDesignFilter";
import { EDesignStatus } from "../../../enum/EDesignStatus";
import { IProductDesignSummaryDto } from "../../../interfaces/DTO/IProductDesignSummaryDto";
import AddCoverSetButton from "./AddCoverSetButton";
import DownloadProjectButton from "./DownloadProjectButton";
import { FTSTheme } from "../../../App";
import OrderSummary from "./OrderSummary/OrderSummary";
import useAppTitle from "../../../hooks/useAppTitle";
import { ENGLISH } from "../../../constants/Languages";

const DesignSummary: React.FC = () => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const navigate: any = useNavigate();

  const { currentUser } = useStoreState((state) => state.auth);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const { thunkGetProductDesignSummaries, thunkAddCoverSet, thunkAddCoverSetToNewProject, setUserDesignData } =
    useStoreActions((actions) => actions.designs);
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);
  const { projectsSummary, productDesignSummary, productDesigns } = useStoreState((state) => state.designs.data);
  const { setIsExportingXLSX, setIsExportingPDF } = useStoreActions((state) => state.app);

  const [coverCost, setCoverCost] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [productDesignSummaryLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>("");

  const appTitle = useAppTitle();
  const currentLanguageCode = localStorage.getItem("lang") as string;

  useEffect(() => {
    refreshProductDesign();
  }, [productDesignSummary.length, productDesigns]);

  useEffect(() => {
    if (projectsSummary.length > 0 && currentProjectId != null) {
      const project = projectsSummary.find((x) => x.id === currentProjectId)!;
      setProjectName(project?.name);
    }
  }, [projectsSummary, currentProjectId]);

  const refreshProductDesign = async () => {
    const productDesignFilter: IProductDesignFilter = { projectId: currentProjectId! };

    const response = (await thunkGetProductDesignSummaries(productDesignFilter)) as IProductDesignSummaryDto[] | Status;

    if ((response as Status) === Status.FAILED) {
      thunkOpenSnackBar({
        message: t("design_summary_refresh_product_design.error.message"),
        severity: "error",
      });

      setLoading(false);
      return;
    }

    const summaries = response as IProductDesignSummaryDto[];

    const orderCost = summaries
      .filter((x) => x.designStatus === EDesignStatus.Completed)
      .reduce((sum, current) => {
        return sum + current.quantity * 10 * current.numberOfCovers;
      }, 0);

    setCoverCost(orderCost);
  };

  const handleAddCoverSet = async () => {
    setLoading(true);

    setUserDesignData(null);

    const productDesignsFromProjectId = productDesigns.filter((x) => x.project?.id == currentProjectId);

    const hasProductDesign = productDesignsFromProjectId.length > 0;

    const result = hasProductDesign ? await thunkAddCoverSet() : await thunkAddCoverSetToNewProject();

    const navigateTo = hasProductDesign ? "/frame-configuration" : "/start-configurator";

    if (result.status === Status.FAILED) {
      thunkOpenSnackBar({
        message: result.message,
        severity: "error",
      });

      setLoading(false);

      return;
    }

    setLoading(false);

    thunkOpenSnackBar({
      message: t("design_summary.helper_text.add_cover_set.success_message"),
      severity: "success",
    });

    navigate(navigateTo, {
      state: {
        isDesignSelected: true,
      },
    });
  };

  const showYouHaveCoverInProject = () => {
    const text = t("DesignSummary_YouHaveCoverInProject", { NumberOfCovers: productDesignSummary.length.toString() });

    return (
      <Typography
        variant="body1"
        fontSize="16px"
      >
        <Trans
          i18nkey="DesignSummary_YouHaveCoverInProject"
          values={{ NumberOfCovers: productDesignSummary.length.toString() }}
        >
          {text}
        </Trans>
      </Typography>
    );
  };

  const handleExportProjectXLSX = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    const currentLanguageCode = localStorage.getItem("lang") || ENGLISH;
    ProjectService.ExportXLSX(currentProjectId!, currentLanguageCode)
      .then((response) => {
        const project = projectsSummary.find((x) => x.id === currentProjectId);
        const currentDate = new Date().toLocaleDateString();

        const element = document.createElement("a");
        const url = URL.createObjectURL(new Blob([response.data]));
        element.href = url;
        element.download = `${project?.name} ${ftsTheme.name} - Current Date ${currentDate}.xlsx`;
        element.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        thunkOpenSnackBar({
          message: t(arrayBufferToString(error.response.data)),
          severity: "error",
        });
      })
      .finally(() => {
        setIsExportingXLSX(false);
      });
  };

  function arrayBufferToString(buffer) {
    let decoder = new TextDecoder("utf-8");
    return decoder.decode(buffer);
  }

  const handleExportProjectPDF = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();

    ProjectService.ExportPDF([currentProjectId!], currentLanguageCode)
      .then((response) => {
        const project = projectsSummary.find((x) => x.id === currentProjectId);
        const currentDate = new Date().toLocaleDateString();

        const element = document.createElement("a");
        const url = URL.createObjectURL(new Blob([response.data]));
        element.href = url;
        element.download = `${project?.name} ${ftsTheme.name} - Current Date ${currentDate}.pdf`;
        element.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        thunkOpenSnackBar({
          message: t(arrayBufferToString(error.response.data)),
          severity: "error",
        });
      })
      .finally(() => {
        setIsExportingPDF(false);
      });
  };

  return (
    <>
      <ExternalLayout
        showNavbarCenteredText={false}
        showNavbarLinks={true}
        showPageHeader={false}
        pageHeaderTextTranslationKey=""
        navbarLogoLink="/"
        navbarMainTitle={appTitle}
        navbarBackGroundColor="#fff"
        bodyBackGroundColor="#fff"
      >
        {(productDesignSummaryLoading && <Spinner />) || (
          <>
            <div className="designSummary">
              <div className="designSummaryContainer">
                <div className="designSummary__headerGrid">
                  <div className="designSummary__groupTitle">
                    <Typography
                      className="designSummary__title"
                      variant="h5"
                    >
                      {projectName}
                    </Typography>
                    <IconButton
                      className="designSummary__projectIcon"
                      onClick={() => {
                        editDialogRef.current?.open();
                      }}
                    >
                      <ReactSVG
                        src={EditIcon}
                        beforeInjection={(svg) => {
                          svg.setAttribute("style", `width: 16px; height: 16px; fill: ${ftsTheme.icon.color};`);
                        }}
                      />
                    </IconButton>
                  </div>
                  <Typography className="designSummary__description">{t("DesignSummary_HeaderDescription")}</Typography>
                </div>
                <div className="designSummary__main">
                  <div className="designSummaryPreview__mainPreview">
                    <Grid
                      container
                      className="designSummaryPreview_startGrid"
                    >
                      <Grid
                        item
                        container
                        alignContent="center"
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <span className="designSummary_coverSetsMessage">{showYouHaveCoverInProject()}</span>
                      </Grid>
                      <Grid
                        item
                        container
                        alignContent="center"
                        alignItems="center"
                        justifyContent="flex-end"
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                      >
                        <AddCoverSetButton
                          loading={loading}
                          handleAddCoverSet={handleAddCoverSet}
                        />

                        {currentUser?.isElBridgeAvailable && (
                          <>
                            <Divider
                              orientation="vertical"
                              className="designSummary_divider"
                            />
                            <DownloadProjectButton />
                          </>
                        )}

                        {/* <Divider
                              orientation="vertical"
                              className="designSummary_divider"
                            /> */}
                        {/* <ShareProjectButton handleClick={handleClick} /> */}
                      </Grid>
                    </Grid>
                    <Grid>
                      <DesignSummaryTable
                        productDesignSummary={productDesignSummary}
                        setCoverCost={setCoverCost}
                      />
                    </Grid>
                  </div>

                  <OrderSummary refreshProductDesign={refreshProductDesign} />
                </div>
              </div>
            </div>

            <DownloadProjectDialog
              handleExportProjectXLSX={handleExportProjectXLSX}
              handleExportProjectPDF={handleExportProjectPDF}
              ref={downloadProjectDialogRef}
            />

            <EditDialog
              ref={editDialogRef}
              name={projectName}
              projectId={currentProjectId!}
              setProjectName={setProjectName}
            />
          </>
        )}
      </ExternalLayout>
    </>
  );
};

export default DesignSummary;
